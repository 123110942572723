import {
  HomeOutlined,
  UserOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  SafetyOutlined,
  GiftOutlined,
  OrderedListOutlined,
  DollarCircleOutlined,
  UserSwitchOutlined,
  ContactsOutlined,
  SettingOutlined
} from "@ant-design/icons";

const sidebar = [
  // {
  //   routeName: "frontend.admin.dashboard.index",
  //   icon: <HomeOutlined />,
  //   routeParams: {},
  // },
  // {
  //   routeName: "frontend.admin.registers.index",
  //   icon: <UserSwitchOutlined />,
  //   routeParams: {},
  //   permissions: {
  //     "registers": "R",
  //   }
  // },
  // {
  //   routeName: "frontend.admin.paymentsIndex",
  //   icon: <SafetyOutlined />,
  //   routeParams: {},
  //   type: "sub",
  //   children: [
      
  //   ]
  // },
  {
    routeName: "frontend.admin.companies.index",
    icon: <ContactsOutlined />,
    routeParams: {},
    type: "sub",
    children: [
      {
        routeName: "frontend.admin.companies.index",
        icon: <ContactsOutlined />,
        routeParams: {},
        permissions: {
          "companies": "R",
        }
      },
      {
        routeName: "frontend.admin.payments.changePackage",
        icon: <UserOutlined />,
        routeParams: {},
        permissions: {
          "payments": "CRUD"
        }
      },
      {
        routeName: "frontend.admin.payments.index",
        icon: <DollarCircleOutlined />,
        routeParams: {},
        permissions: {
          "payments": "R",
        }
      }
      // {
      //   routeName: "frontend.admin.userPermissions.index",
      //   icon: <SettingOutlined />,
      //   routeParams: {},
      //   permissions: {
      //     "companies": "R"
      //   }
      // }
    ]
  },
  {
    routeName: "frontend.admin.packagesIndex",
    icon: <SafetyOutlined />,
    routeParams: {},
    type: "sub",
    children: [
      {
        routeName: "frontend.admin.packages.index",
        icon: <SafetyOutlined />,
        routeParams: {},
        permissions: {
          "packages": "R"
        }
      }
      // {
      //   routeName: "frontend.admin.packageBonus.index",
      //   icon: <GiftOutlined />,
      //   routeParams: {},
      //   permissions: {
      //     "packages": "R"
      //   }
      // }
    ]
  },
  {
    routeName: "frontend.admin.adminsIndex",
    icon: <UserOutlined />,
    routeParams: {},
    type: "sub",
    children: [
      {
        routeName: "frontend.admin.adminGroups.index",
        icon: <UsergroupAddOutlined />,
        routeParams: {},
        permissions: {
          "adminGroups": "R",
        }
      },
      {
        routeName: "frontend.admin.admins.index",
        icon: <UserAddOutlined />,
        routeParams: {},
        permissions: {
          "admins": "R",
        }
      },
      {
        routeName: "frontend.admin.adminPermissions.index",
        icon: <OrderedListOutlined />,
        routeParams: {},
        permissions: {
          "root": "U",
        }
      }
    ]
  },
  {
    routeName: "frontend.admin.settings.index",
    icon: <SettingOutlined />,
    routeParams: {},
    permissions: {
      "root": "R"
    }
  }
];

export default sidebar;
