import BaseApi from './BaseService'

class Company extends BaseApi {
  constructor(context?: Context) {
    super(context)
    this.name = 'companies';
  }

  async getPackages(data: any) {
    const url = this.route(`api.${this.name}.getPackages`, data).href
    return await this.Request.get(url, data)
  }

  async addPackage(data: any) {
    const url = this.route(`api.${this.name}.addPackage`, data).href
    return await this.Request.post(url, data)
  }

  async changePackage(data: any) {
    const url = this.route(`api.${this.name}.changePackage`, data).href
    return await this.Request.post(url, data)
  }

  async loginCompany(data: any) {
    const url = this.route(`api.${this.name}.loginCompany`).href
    let result = await this.Request.post(url, data)
    //success
    window.location = result;
    return true;
  }
}

export default Company