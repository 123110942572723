import React, { Fragment, createRef, useEffect, useState } from 'react';
import { Menu, Layout, Avatar, Button, Input, Popover, Descriptions, Row, Col, Tooltip, AutoComplete } from 'antd';
import { 
  MenuUnfoldOutlined, MenuFoldOutlined, PoweroffOutlined, 
  SettingFilled, TeamOutlined, MoneyCollectOutlined, RightOutlined, BellOutlined, UserOutlined
} from '@ant-design/icons'
import ConfirmDialog from 'themes/components/Dialogs/ConfirmDialog';
import AdminService from  "../../services/api/AdminService"
import useBaseHook from 'themes/hooks/BaseHooks'
import Cookies from 'universal-cookie'
import to from 'await-to-js'
// import Layout from 'themes/layouts/Admin'
import moment from 'moment'
import _ from 'lodash'


const { Header } = Layout;
const { SubMenu } = Menu
const { Search } = Input

const AdminHeader = (props: any) => {
  const { t, notify, redirect, setStore, getAuth, getStore, getCookies, getData  } = useBaseHook();
  const adminService = new AdminService()
  const [customers, setCustomers] = React.useState([])
  const [products, setProducts] = React.useState([])
  const [users, setUsers] = React.useState([])
  const auth = getAuth() || {};
  const cookieObject = getCookies()
  const confirmRef = createRef<ConfirmDialog>()
  const handleClickMenu = (e: any) => { }
  const redirectLogin = () => {
    const cookies = new Cookies(cookieObject)
    cookies.remove('admin_token')
    cookies.remove('admin')
    redirect('frontend.admin.login')
  }
  useEffect(() => {
    if (!cookieObject.admin_token || !cookieObject.admin) {
      redirect('frontend.admin.login')
    }
  }, [])
  
  const onChangeSearch = async (value: any) => {
    let filterCustomer = {
      filters: [{
        field: "fullname",
        operator: "contains",
        value: value
      }],
      sorting: [],
      pageSize: 5,
      page: 0
    }
    let filterProduct = {
      filters: [{
        field: "name",
        operator: "contains",
        value: value
      }],
      sorting: [],
      pageSize: 5,
      page: 0
    }
    let filterUsers = {
      filters: [{
        field: "username",
        operator: "contains",
        value: value
      }],
      sorting: [],
      pageSize: 5,
      page: 0
    }
    // let [errCustomer, customers]: any[] = await to(customerService.index(filterCustomer))
    // let dataCustomers = _.get(customers, "data", [])
    // if(dataCustomers.length){
    //   setCustomers(dataCustomers)
    // }
    // let [errProduct, products]: any[] = await to(productService.index(filterProduct))
    // let dataProduct = _.get(products, "data", [])
    // if(dataProduct.length){
    //   setProducts(dataProduct)
    // }
    let [errUsers, admins]: any[] = await to(adminService.index(filterUsers))
    let dataUsers = _.get(admins, "data", [])
    if(dataUsers.length){
      setUsers(dataUsers)
    }
    
  }
  const onBlurSearch = () => {
    setCustomers([])
    setProducts([])
    setUsers([])
  }
  const renderConfirmDialog = () => {
    return (
      <ConfirmDialog
        ref={confirmRef}
        onSubmit={redirectLogin}
        title={t('signout')}
        content={t('message.signoutConfirm')}
      />
    )
  }

  const renderRightContent = () => {
    return <div>
      <Button icon={<PoweroffOutlined />} type="link" className="header-btn" onClick={() => { if (confirmRef.current) confirmRef.current.show() }}></Button>
      <Button icon={<SettingFilled spin />} type="link" className="header-btn"></Button>
    </div>
  }

  const { collapsed, onCollapseChange } = props
  const menuIconProps = {
    className: "trigger",
    onClick: () => onCollapseChange(!collapsed)
  }
  let headerClass = "header"
  if (collapsed) headerClass += ' collapsed'

  const accountPopup = () => {
    return <React.Fragment>
      <div style={{ width: "400px" }}>
        <Row gutter={[8, 8]}>
          <Col xs={12} md={7} lg={7}> 
            <div className="avatar">
              <Avatar 
                icon={<UserOutlined />} 
                src={"https://ak0.picdn.net/shutterstock/videos/9291710/thumb/10.jpg"} size={100} shape="square">
                {"customer.fullname"}
              </Avatar>
              {/* <Avatar size={64} icon={<UserOutlined />} /> */}
            </div>
          </Col> 
          <Col xs={12} md={17} lg={17}>
            <Row>
              <b>{t('username')}</b>: <i>{auth.username}</i>
            </Row>
            <Row>
            <b>{t('fullname')}</b>: <i>{auth.fullname}</i>
            </Row>
            <Row>
              <b>{t('customers.email')}</b>: <i>{auth.userEmail ? auth.userEmail : t("update")}</i>
            </Row>
            <Row>
              <b>{t('customers.phone')}</b>: <i>{auth.userPhone ? auth.userPhone : t("update")}</i>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  };
  const renderTitle = (title: string) => {
    return (
      <span>
        {title}
        {/* <a
          style={{ float: 'right' }}
          href="https://www.google.com/search?q=antd"
          target="_blank"
          rel="noopener noreferrer"
        >
          more
        </a> */}
      </span>
    );
  };
  const renderItem = (title: string, link: any) => {
    return {
      value: title,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {title}
          <span>
            {link}
          </span>
        </div>
      ),
    };
  };
  let optionsCustomers: any = []
  let optionsProducts: any = []
  let optionsUsers: any = []
  customers.map((item: any, index: number) => {
    optionsCustomers.push(renderItem(
      `${item.fullname}`, 
      <a style={{ float: 'right' }} href={`customers/${item.id}/edit`} target="_blank" rel="noopener noreferrer"> Xem chi tiết </a>
    ))
  })
  products.map((item: any, index: number) => {
    optionsProducts.push(renderItem(
      `${item.name} - ${item.code}`, 
      <a style={{ float: 'right' }} href={`customers/${item.id}/edit`} target="_blank" rel="noopener noreferrer"> Xem chi tiết </a>
    ))
  })
  users.map((item: any, index: number) => {
    optionsUsers.push(renderItem(
      `${item.fullname} - ${item.username}`, 
      <a style={{ float: 'right' }} href={`users/${item.id}/edit`} target="_blank" rel="noopener noreferrer"> Xem chi tiết </a>
    ))
  })
 
  const options = [
    {
      label: renderTitle('Khách hàng'),
      options: optionsCustomers,
    },
    {
      label: renderTitle('Sản phẩm'),
      options: optionsProducts
    },
    {
      label: renderTitle('Nhân viên'),
      options: optionsUsers
    }
  ];

  const renderSearch = () => {
    return (
      <div style={{ height: "900px", display: 'inline' }}>
        <AutoComplete
          dropdownClassName="certain-category-search-dropdown"
          dropdownMatchSelectWidth={350} // width dropdown
          onChange={(value: any) => onChangeSearch(value)}
          onBlur={() => onBlurSearch()}
          style={{ width: 350 }} // width input Search
          options={options}
        >
          <Input.Search size="large" placeholder="Tìm kiếm ... " />
        </AutoComplete>
      </div>
    );
  };

  return <React.Fragment>
    <Header
      className={headerClass}>
      {collapsed 
      ? <MenuUnfoldOutlined style={{ color: "#fff" }} {...menuIconProps} /> 
      : <MenuFoldOutlined style={{ color: "#fff" }} {...menuIconProps} />}
      
      <Popover placement="bottom" title={t('personalInfo')} content={accountPopup()}>
        <Button icon={<UserOutlined />} type="link" className="header-btn"></Button>
      </Popover>
      
      <Tooltip title={t('revenue.revenue')}>
        <Button icon={<MoneyCollectOutlined />} type="link" className="header-btn" onClick={() => redirect('frontend.admin.revenue.index')}></Button>
      </Tooltip>
      <Button icon={<BellOutlined />} type="link" className="header-btn"></Button>
      {/* <Search
        placeholder="Tìm kiếm..."
        onSearch={value => console.log(value)}
        className="header-search"
      /> */}
      {renderSearch()}
      <div className='rightContainer'>{renderRightContent()}</div>

    </Header>
    {renderConfirmDialog()}
  </React.Fragment>
}

export default AdminHeader