import BaseApi from './BaseService'

class Admin extends BaseApi {
  constructor(context?: Context) {
    super(context);
    this.name = 'admins';
  }

  async login({ data }: {data: {username: string, password: string}}) {
    const url = this.route("api.login").href
    let result = await this.Request.post(url, data) || {}
      //success
    this.cookies.set('admin_token', this.getData(result, "token", undefined), {
      path: "/"
    })
    this.cookies.set('admin', this.getData(result, "admin", {}),{
      path: "/"
    })
    this.redirect("frontend.admin.home")
    return result;
  }
  
  async changePassword(data: { id: number, password: string}){
    const url = this.route(`api.${this.name}.changePassword`, data).href
    let result = await this.Request.put(url, data)
    return result.data;
  }
  // async search(data: any){
  //   const url = this.route(`api.${this.name}.search`, data).href
  //   return await this.Request.get(url, data)
  // }
}

export default Admin