import React from 'react';
import { Row, Col, Avatar, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import useBaseHook from 'themes/hooks/BaseHooks'
import useSWR from 'swr'
import CompanyService from 'themes/services/api/CompanyService'
function numFormatter(num: number) {
    if(num > 999 && num < 1000000){
        return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
    }else if(num > 1000000){
        return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
    }else if(num < 900){
        return num; // if value < 1000, nothing to do
    }
}
const Profile = () => {
    const { getCookies, redirect, t, getData, getAuth } = useBaseHook()
    const auth = getAuth();
    const companyService = new CompanyService()
    // const { data, error } = useSWR('companyService.sumaryInfo', () => companyService.sumaryInfo())
    const profileInfo = {
        companyLogo: "https://thememakker.com/templates/oreo/html/assets/images/profile_av.jpg",
        groupName: "Nhân viên",
        userFullname: "Đỗ Duy Ngọc",
        companyName: "Anh Đào Hair Salon CS2",
        companyAddress: "Số 39, ngõ 74, Đường Duy Tân, Quận Cầu Giấy, Hà Nội",
        today: {},
        
    }
    return <div className="sidebar-profile">
        <Row>
            <Col xs={24}>
                <div className="profile-avatar">
                    <Avatar size={80} icon={<UserOutlined />} src="/static/img/logo-400.png" className="avatar" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <div className="profile-companyName">Fiup.vn</div>
                <div className="profile-address">{profileInfo.companyAddress}</div>
            </Col>
            {/* <Col xs={24}>
                <div className="profile-userInfo">
                    <span className="profile-name">{profileInfo.userFullname}</span> - <span className="profile-groupName">{profileInfo.groupName}</span>
                </div>
            </Col> */}
        </Row>
        <Row>
            <Col xs={22} offset={1}>
                <Divider className="profile-endLine" />
            </Col>
        </Row>


    </div>
}

export default Profile